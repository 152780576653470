@import url(https://fonts.googleapis.com/css2?family=Inconsolata&display=swap);
* {
  background-color: rgba(255, 255, 255, 1);
}

.container {
  opacity: 1;  
  padding-top: 16px; /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
  animation: fadein 0.5s;
}

body {
  margin: 0;
  font-family: 'Inconsolata', monospace;
  padding: 4px;
}

.App {
  height: 100vh;
}

.album-art {
  height: 100px;
  width: 100px;
  cursor: pointer;
}

.title {
  font-size: 32px;
  margin-left: 16px;
  margin-right: 16px;  
}

.subtext {
  font-size: 16px;
  margin-top: 8px;
  margin-left: 16px;
  margin-right: 16px;
}

hr {
  width: 16vw;
  margin-top: 32px;
}

.link {
  margin-top: 32px;
  font-size: 24px;
}

.link > a {
  position: relative;
  color: #000;
  text-decoration: none;
}

.link > a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000;
  visibility: hidden;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

.link > a:hover {
  color: #000;
}

.link > a:hover::before {
  visibility: visible;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

.current {
  color: rgb(0, 0, 0, 0.25);
  font-size: 15px;
  font-weight: 100;
  margin-top: 24px;
  padding: 8px;
  text-shadow: .5px .5px .5px rgb(126, 126, 126); 
}

.music-subtitle {
  margin-top: 16px;
}

.music-text {
  color: rgb(0, 0, 0, 0.25);
  font-size: 15px;
  font-weight: 100;
  margin-top: 0%;
  padding: 8px;
  text-shadow: .5px .5px .5px rgb(126, 126, 126); 
}

@media (min-width: 350px) {
  .title {
    font-size: 48px;
  }
  .subtext {
    font-size: 24px;
  }
}

@media (min-width: 650px) {
  .title {
    font-size: 64px;
  }
  .subtext {
    font-size: 32px;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */

/* Opera < 12.1 */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

